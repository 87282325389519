@media print {
    body {
        font-family: Arial, sans-serif;
    }
    .no-print, footer, header, a[href]:after {
        display: none !important;
    }
    .tab-content {
        padding: 20px;
        border: none;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid black;
        padding: 10px;
        text-align: left;
    }
    h2, h3 {
        color: black;
    }
}
